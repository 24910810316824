import React from 'react'

function ColFive() {
    return (
        <div>
            <h4>Media</h4>
            <ul class="list-unstyled" style={{color: "#999999"}}>
                <li>AMNAYA News</li>
                <li>Convertible Bond</li>
                <li>Portfolio News</li>
                <li>Downloads</li>
            </ul>
        </div>
    )
}

export default ColFive
