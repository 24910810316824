import React from "react";
import Image from "../../Assets/goal.png";
function GoalMain() {
    const line1={
        backgroundColor: "#212428",
        height: 1,
        marginTop: 20,
        marginBottom: 40
    
      };
      const line2={
        backgroundColor: "#212428",
        height: 3,
        width: "15%",
        borderRadius: 10,
      };
      const contain = {
        padding: 20,
      };
  return (
    <div style={contain}>
        <div style={line1}><div style={line2}></div></div>
      <div className="text-center">
        {/* <h2 style={{marginTop: 30, marginBottom: 30}}>AMNAYA Subscribes To U.N. Sustainable Development Goals.</h2> */}
        <img src={Image} alt="pictur" className="img-fluid" />
      </div>
    </div>
  );
}

export default GoalMain;
