import React from "react";
import approva from "../../Assets/img/aporvabgpic.jpg";
import suresh from "../../Assets/img/suresh_chand.jpg";


export const Leaders = [{
        Img: approva,
        title: "Apoorva Srivastava",
        sub: "Founder/Director/Board Member",
        info: "Researcher and Businessman based in Philadelphia, US. Apoorva has experience working into Financial Econometrics both on research and business sides. He is responsible for investments and business development."
    },
    {
        Img: suresh,
        title: "Suresh Chandra Srivastava",
        sub: "Director/Board Member",
        info: "Banker and Administrator, Suresh handles all legalities, compliances and investments globally and for all subsidiaries of AMNAYA.",
        btn: "Read More"
    },

];