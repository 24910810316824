import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import Image from "../../Assets/Group_12.png" 

function NavBar() {
  const [click, steClick] = useState(false);

  const handleClick = () => steClick(!click);
  const text = {
    // fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    textDecoration: 'none'
  };

  return (
    <>
      <div className="navbar nav-container">
        <NavLink
          exact
          to="/"
          //  activeClassName="active"
          className="nav-logo"
        >
          <img
            src={Image}
            class="img-fluid"
            alt="logo"
            height="40"
            width="90"
          />
        </NavLink>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <NavLink
              exact
              to="/offer"
              // activeClassName="active"
              className="nav-links"
              onClick={handleClick}
              style={text}
            >
              WHAT WE DO
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to="/career"
              // activeClassName="active"
              className="nav-links"
              onClick={handleClick}
              style={text}
            >
              CAREERS
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to="/investor"
              // activeClassName="active"
              className="nav-links"
              onClick={handleClick}
              style={text}
            >
              INVESTORS
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to="/media"
              // activeClassName="active"
              className="nav-links"
              onClick={handleClick}
              style={text}
            >
              MEDIA
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to="/login"
              // activeClassName="active"
              className="nav-links"
              onClick={handleClick}
              style={text}
            >
              LOGIN
            </NavLink>
          </li>
         
        </ul>
        <div className="nav-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </div>
    </>
  );
}

export default NavBar;
