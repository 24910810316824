export const JobData=[{
    title: "Investments",
    about: "Join a global seed and growth investor that empowers gifted entrepreneurs.",
    number: "7"
},
{
    title: "Finance",
    about: "Optimize all aspects of finance in a complex and global environment. Lead structuring, reporting, treasury, M&A and advice local companies.",
    number: "6"
},
{
    title: "Investments",
    about: "Join a global seed and growth investor that empowers gifted entrepreneurs.",
    number: "1"
},
]