import React from 'react'
import DiscriptionMain from './../Discription/DiscriptionMain';
import Content from "../../Assets/Amvegan.pdf";
import DiscriptionHero from '../Discription/DescriptionHero';
import DescriptionInfo from './../Discription/DescriptionInfo';
import DescriptionButton from './../Discription/DescriptionButton';
import Footer_Amnaya from './../Footer/Footer';


function Coffee() {
    return (
        <div>
        <DiscriptionHero />
            <DescriptionInfo />
            <DescriptionButton 
                link="https://amnayacorpcom-my.sharepoint.com/:p:/g/personal/apoorva_amnayacorp_com/ETI01gpUAlZIj2ojxl_J8WwB2gP58RUw-qjgRCh6BS6ZkQ?rtime=efVJPxOw2Ug"
                Content={Content}
            />
            <Footer_Amnaya />
            
        </div>
    )
}

export default Coffee
