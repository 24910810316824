import React from 'react'
import PeopleIcon from '@mui/icons-material/People';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MailIcon from '@mui/icons-material/Mail';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
export const Sidebardata = [{
        title: "Leadership & Governance",
        icon: < BusinessCenterIcon / > ,
        link: "/leadership-governance",
    },
    {
        title: "Financial Information",
        icon: < CreditCardIcon / > ,
        link: "/financial",
    },
    {
        title: "Financial Calendar",
        icon: < CalendarTodayIcon / > ,
        link: "/financial-calender",
    },

    {
        title: "Contact",
        icon: < MailIcon / > ,
        link: "/contact",
    },


]