import React from 'react'
import Sidebar from './Sidebar/Sidebar';
import { Container } from 'react-bootstrap';


function Financial() {
    return (
      <>
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="row">
              <div className="col-md-12">
                <h3 style={{ marginLeft: 10 , marginBottom: 40}}>Financial Information</h3>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Financial
