import React from "react";
import "../../App.css";
function First() {
  const contain = {
    // backgrounColor: "#E5E5E5",
    // width: "100%",
    height: 750,
    padding: 30,
    // paddingTop: 200,    
    alignItem: "center",
    justifyContnent: "center",
  };
  return (
    <div className="row back text-center" style={contain}>
      <div className="col-12 text-center box_center" >
        <h3 className=" text-center" id="head" style={{fontSize: 40, fontWeight: "bolder"}}>AMNAYA subscribes to UN Sustainable Development Goals -2030. We are a committed entity striving to make businesses for masses around the world.</h3> 
        <h4 style={{color: "#606060", fontWeight: 600}}>Blockchain and AI enabled Business Models focused on Sustenance.</h4>
      </div>
     
</div>
  );
}

export default First;
