import React from 'react'
import { Card,Button } from 'react-bootstrap';
import { Redirect } from "react-router";
import Image from "../../Assets/Group_12.png";
import { useHistory } from 'react-router-dom';


function WeCard(props) {
  const contain = {
    width: "18rem",
    padding: 20,
    margin: 20,
    height: 450,
  };
  const logo = {
    width: 120,
    height: 140,
  };
  const line = {
    borderWidth: 3,
    color: "#606060",
  };
  const card_body = {
    padding: 0,
  };
  const card_heading = {
    marginTop: -16,
    color: "#414040",
    //  fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 800,
  };
  const btn = {
    width: "100%",
    borderRadius: 0,
    //  fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
  };
  const info = {
    color: "#606060",
    //  fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 40,
    height: 160,
  };
  const history = useHistory();
  return (
    <Card style={contain}>
      <Card.Img variant="top" src={props.Img} style={logo} />
      <hr style={line} />

      <Card.Body style={card_body}>
        <Card.Title style={card_heading}>{props.title}</Card.Title>
        <Card.Text style={info}>{props.info}</Card.Text>
        {/* <Button
          variant="outline-secondary"
          style={btn}
          // onClick={() => history.push('/discription')}
        >
          {props.btn}
        </Button> */}
        {/* <button BaseClasses className="row" id={window.location.pathname == val.link ? "active" : ""} key={key} onClick={()=>{window.location.pathname = val.link}}  >Read More</button> */}

      </Card.Body>
    </Card>
  );
}

export default WeCard
