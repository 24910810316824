import React from 'react'
import Image from "../../Assets/investment.jpg";

function InvestmentAbout() {
    const l_col={
        width: 350,
    };
    const head={
        marginBottom: 40
    }
  return (
    <div>
      <div className="row m-0 container-fluid">
        <div
          className="col-12 d-flex text-center justify-content-center"
          style={{ marginBottom: 30, width: "100%" }}
        >
          <img src={Image} className="img-fluid" alt="Background_image" />
        </div>
      </div>
      <div className="container" style={{marginBottom: 40 , padding: 40}}>
        <h1 style={head}>About</h1>
        <div className="row">
          <div className="col-md-6 justify-content-end" >
            <div style={l_col} >
             <h3>We back companies across all stages and throughout the lifecycle.</h3> 
            </div>
          </div>
          <div className="col-md-6">
            From day zero through all the stages of growth, we support our
            founders through our platform. We have backed entrepreneurs from
            series seed to IPO on all continents. Join a global seed and growth
            investor that empowers gifted entrepreneurs.
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvestmentAbout
