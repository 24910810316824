import React from 'react'
import { Container } from 'react-bootstrap'
import Sidebar from './Sidebar/Sidebar'

function Covertible() {
    return (
        <div>
            <div className="row">
                <div className="col-md-2 col-1">
                    {/* <Sidebar/> */}
                </div>
                <div className="col-md-10 col-11">
                <div className="row">
                    <div className="col-md-12">
                        <Container>
                            <h3 style={{marginLeft:10}}>Covertible Bond</h3>
                        </Container>
                    </div>                
                </div>
                
                </div>
            </div>
        </div>
    )
}

export default Covertible
