import React from 'react'
import JobCard from './JobCard';
import Image from "../../Assets/media.jpg"
import { JobData } from './JobData';
import Footer_Amnaya from '../Footer/Footer';

function JobMain() {
    return (
        <div>
        <div className="row m-0 container-fluid" >
                <div className="col-12 d-flex text-center justify-content-center" style={{height:700,marginBottom:30}}>
                    <img src={Image} 
                     className="img-fluid" 
                         alt="Background_image"
                     />
                </div>
            </div>
        <div className="container" >
        <div className="row m-0">
        {
            JobData.map((val) =>
            <div className="col-md-6">
            <JobCard
            title={val.title}
            about={val.about} 
            number={val.number}
            />
            </div>
            )
        }
        </div>
            
        </div>
        <Footer_Amnaya />
        </div>
    )
}

export default JobMain
