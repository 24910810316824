import React from 'react'
import Career from '../../Assets/career.jpg';
import Type from './Type';
import Location from './Location';
import Perk from './Perk';
import Footer_Amnaya from '../Footer/Footer';

function Main() {
    return (
        <>
            <div className="row " >
                <div className="col-12 d-flex text-center justify-content-center" style={{height:700,marginBottom:30}}>
                    <img src={Career} 
                     className="img-fluid" />
                </div>
            </div>
            <Type/>
            <Location/>
            <Perk/>
            <Footer_Amnaya/>
        </>
    )
}

export default Main
