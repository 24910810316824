import React from 'react'
import WeDo from './../WeDo/WeDo';
import MidMain from './../Mid/MidMain';
import GoalMain from '../Goal/GoalMain';
import Footer_Amnaya from './../Footer/Footer';
import First from './First';

function Main() {
    return (
        <div>
            <First />
            {/* <WeDo /> */}
            <GoalMain />
            <Footer_Amnaya />
        </div>
    )
}

export default Main
