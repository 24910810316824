import React from "react";
import logo1 from "../../Assets/img/newLogos/coto.jpg";
import logo2 from "../../Assets/img/newLogos/gvcCoffee.jpg";
import logo3 from "../../Assets/img/newLogos/gvcDairy.jpg";
import logo4 from "../../Assets/img/newLogos/gvcEnergy.jpg";
import logo5 from "../../Assets/img/newLogos/gvcSaffron.jpg";
import logo6 from "../../Assets/img/newLogos/gvcTea.jpg";
import vigil from "../../Assets/img/newLogos/vigil.jpg";
import amvegan from "../../Assets/img/Logo1.jpg";

export const CardData = [{
        Img: logo1,
        title: "COTO",
        info: "COTO aka ‘Carpets of the Orient’ is ‘blockchain’ and ‘Artificial Intelligence’ enabled ‘Bidders Platform’ for global carpets and rugs industry. COTO is headquartered in London, UK.",
        btn: "Read More",
        link: "/coto",

    },
    {
        Img: logo3,
        title: "GVC-Dairy",
        info: "AMNAYA Global Value Chain for Dairy Products is a blockchain and Artificial Intelligence enabled exporting platform dairy products like goat cheese and camel milk based cooperatives. Such GVC-D caters globally and is based out of London, UK.",
        btn: "Read More",
        link: "/dairy",

    },
    {
        Img: logo5,
        title: "GVC-Saffron",
        info: "AMNAYA Global Value Chain for Saffron is a blockchain and Artificial Intelligence enabled exporting platform for saffron between Iran and India. Such GVC-S caters globally and is based out of London, UK.",
        btn: "Read More",
        link: "/saffron",

    },
    {
        Img: logo6,
        title: "GVC-Tea",
        info: "AMNAYA Global Value Chain for Tea is a blockchain and Artificial Intelligence enabled exporting platform for tea from India and Sri Lanka.The GVC-T caters globally and is based out of London, UK.",
        btn: "Read More",
        link: "/tea",

    },
    {
        Img: logo2,
        title: "GVC-Coffee",
        info: "AMNAYA Global Value Chain for Coffee is a blockchain and Artificial Intelligence enabled exporting platform for coffee from Kenya. Such GVC-C caters globally and is based out of London, UK.",
        btn: "/coffee"
    },
    {
        Img: logo4,
        title: "GVC-Energy",
        info: "AMNAYA Energy Blockchcain and AI enabled energy venture for oil and natural gas. The venture is a comprehensive platform dedicated to overcome unscrupulous trade channels and agents involved in the energy value chain globally. Based in New York City, London, Abu Dhabi and Singapore.",
        btn: "/energy"
    },
    {
        Img: vigil,
        title: "Vigil",
        info: "Vigil is a blockchain and Artificial Intelligence platform that enables verification of academic credentials worldwide, verifies experiential learning, and will consequently help in overcoming the huge student debt in US. Vigil is based in San Francisco Bay Area, US.",
        btn: "/vigil"
    },
    {
        Img: amvegan,
        title: "AmVegan",
        info: "AmVegan is dedicated venture for meat alternatives and based in San Francisco Bay Area, CA. The entity is responsible for managing the supply chain of meat alternatives.",
        btn: "/amvegan"
    }

];