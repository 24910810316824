import React from 'react'

function Perk() {
    return (
       <>
       <div className="row" style={{padding:10}}>
       <div className="col-md-6" >
              <p  style={{color:'#000',fontFamily:'Neuzeit, sans-serif',fontSize:40,paddingTop:100}}>We offer you a great space to work: a place where you can seize opportunities, take initiative and have a real impact.</p>
        </div>
        <div className="col-md-3" style={{paddingTop:100}}>
            <h5>A Great Network</h5>
            <p>NYC attracts people for all kinds of reasons – it’s laid-back, has an open-minded culture, reasonable rents and beer that’s cheaper than water. An affordable public transport system and bicycle-friendly streets mean getting around is easy and pleasant. All this, and much more attracts exciting talent from all over the world, with regular networking events, international conferences and tech summits being held to foster knowledge sharing.</p>
            </div> 
        <div className="col-md-3" style={{paddingTop:100}}>
            <h5>Pulse of United States</h5>
            <p>Working in NYC means enjoying all the benefits of life in United States – a country with one of the best universal health care systems in the world – and a thriving economy. NYC’s central location provides you with quick access to most major American cities and financial hubs. NYC has become a magnet for young, ambitious founders and talented tech experts.</p>
            <div className="text-right"><a className="margin-top-l btn btn-xs-block btn-default btn-lg" href="/job">view AMNAYA jobs</a></div>
        </div>
               
       </div>
       </>
    )
}

export default Perk
