import React from "react";
import { Button, Container } from "react-bootstrap";
import Content from "../../Assets/Amvegan.pdf";

function DescriptionButton(props) {
  return (
    <Container style={{ marginBottom: 40 }}>
      <div className="row m-0 d-flex text-center justify-content-end">
        <div className="col-md -12 col-12 d-flex text-center justify-content-center"></div>
        {/* <div className="col-md-6 col-12 d-flex text-center justify-content-start">
          <Button variant="outline-primary" style={{marginBottom: 10}}>Download PPT</Button>
        </div>*/}
        <div class="text-center">
          <Button
            variant="outline-primary"
            style={{ marginBottom: 10, textDecoration: "none" }}
          >
            <a
              href={props.link}
              rel="noreferrer"
              target="_blank"
            >
              {" "}
              View PPT
            </a>
          </Button>
          <Button
            variant="outline-primary"
            style={{ marginBottom: 10, textDecoration: "none" }}
          >
            <a href={props.Content} target="_blank" rel="noreferrer">
              Download PDF
            </a>
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default DescriptionButton;
