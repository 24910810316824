import React from "react";

function ColOne() {
  return (
    <div>
      <h1>AMNAYA</h1>
      <p style={{color: "#999999"}}>
        AMNAYA ensures 'Sustenance is Empowerment'-Maximum Trickle Down of
        Benefits to the Masses. Additionally, Transparency and Accountability
        are Virtues connecting with the world at large. 
        <br />
        <br />
        <br />
        <br /><br />
        Imprint & Privacy Policy
      </p>
    </div>
  );
}

export default ColOne;
