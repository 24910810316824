import React from "react";
import { Card } from "react-bootstrap";
import { propTypes } from "react-bootstrap/esm/Image";
import { NavLink } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function JobCard(props) {
  const head = {
    marginTop: 20,
    marginBottom: 20,

    fontWeight: "bold",
  };
  const discription = {
    color: "#666",
    height: 120,
  };
  const position = {
    color: "#666",
  };
  const contain = {
    marginBottom: 20,
  };
  return (
    <div style={contain}>
      <div
        style={{
          backgroundColor: "#000",
          height: 1,
          width: "100%",
          borderRadius: 5,
        }}
      >
        <div
          style={{
            backgroundColor: "#000",
            height: 5,
            width: "40%",
            borderRadius: 5,
            paddipngBottom: 20,
          }}
        ></div>
      </div>
      <div style={{ padding: 20 }}>
        <h3 style={head}>{props.title}</h3>
        <h6 style={discription}>{props.about}</h6>
        <h6 style={position}>
          {props.number} Positions
          <NavLink to="/job_discription"><ArrowForwardIcon /></NavLink>
        </h6>
      </div>
    </div>
  );
}

export default JobCard;
