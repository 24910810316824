import React from "react";

export const TrunkeyData = [{
        title: "Patent Research Outsourcing for a US based Biopharmaceuticals Giant",
        country: "India",
        btn: "Download"
    },
    {
        title: "Olive Farms and Related Technology",
        country: "India and Adjacent Territories",
        btn: "Download"
    },
    {
        title: "Goat Cheese Manufacturing Plants",
        country: "India",
        btn: "Download"
    },
    {
        title: "Gecko Research Laboratory for Cancer Treatment",
        country: "India/Bangladesh	",
        btn: "Download"
    },
    {
        title: "Medical Insurance Process based BPO – Voice Calling",
        country: "India/Phliippines	",
        btn: "Download"
    },
    {
        title: "Blockchain and AI enabled Platform for Multi Family Properties",
        country: "India",
        btn: "Download"
    },
    {
        title: "Setting up Schools based on US Schooling Systems, known as American Schools",
        country: "India",
        btn: "Download"
    },
    {
        title: "Robotics Process Automation based Service Platforms for Pharmaceutical/Manufacturing.",
        country: "India",
        btn: "Download"
    },
    {
        title: "Medical Tourism(Uberized Form)",
        country: "India",
        btn: "Download"
    },
    {
        title: "Data Vault for Vigilance and Security Systems",
        country: "India/Indonesia",
        btn: "Download"
    },
    {
        title: "Olive based Wine Manufacturing",
        country: "India",
        btn: "Download",
    },
    {
        title: "Carpets Cluster enabled by Blockchain and Artificial Intelligence",
        country: "India/Poland/Romania",
        btn: "Download"
    },
    {
        title: "Knowledge Process Outsourcing using AI",
        country: "India",
        btn: "Download"
    },
    {
        title: "Solar Power Networking using Blockchain for Effective Distribution",
        country: "GLOBAL",
        btn: "Downoad"
    }

];