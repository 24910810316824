import { grey } from '@mui/material/colors';
import React from 'react'
import { Card,Button } from 'react-bootstrap';
import Image from "../../Assets/Group_12.png";


function InCard(props) {
  const contain={
    width: "18rem",
    padding: 20 ,
    height: 450
};
const logo={
   width: 120 ,
};
const line={
    borderWidth: 3, 
    color: "#606060" ,
};
const card_body={
    padding: 0,
    marginBottom:10
};
const card_heading={
   marginTop: -16,
   color: "#414040",
   fontStyle: "normal",
   fontWeight: 800,
};
const sub_heading={
  marginTop: 16,
  color: "#3E7DC7",
  fontStyle: "normal",
  fontWeight: 800,
};

const info={
   color: "#606060",
   fontStyle: "normal",
   fontWeight: 430,
   fontSize: 14,
   marginBottom: 40
}
    return (
      
<Card style={contain}>
  <Card.Img variant="top" src={props.Img} style={logo}/>
  <hr style={line} />
  <Card.Body style={card_body}>
    <Card.Title style={card_heading}>{props.title}</Card.Title>
    <Card.Title style={sub_heading}>{props.sub}</Card.Title>
    <Card.Text style={info}>
      {props.info}
    </Card.Text>
  </Card.Body>
</Card>   
    )
}

export default InCard
