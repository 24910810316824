import React from "react";
import ColFive from "./ColFive";
import ColFour from "./ColFour";
import ColOne from "./ColOne";
import ColThree from "./ColThree";
import ColTwo from "./ColTwo";

function Footer_Amnaya() {
  const contain = {
    backgroundColor: "#212428",
    padding: 20,
    color: "#fff",
  };
  const cent = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const line1 = {
    backgroundColor: "#FFF",
    height: 1,
    marginTop: 50,
    marginBottom: 50,
  };
  const line2 = {
    backgroundColor: "#FFF",
    height: 3,
    width: "15%",
    borderRadius: 10,
  };
  const icon = {
    marginTop: -40,
    height: 45,
    width: 45,
    backgroundColor: "#fff",
    borderRadius: 20,
    justifyContent: "center",
    marginRight: 10,
  };
  return (
    <div style={contain}>
      <div style={line1}>
        <div style={line2}></div>
      </div>
      <div>
        <div className="row">
          <div className="col-md-4" style={cent}>
            <ColOne />
          </div>
          <div className="col-md-2">
            <ColTwo />
          </div>
          <div className="col-md-2">
            <ColThree />
          </div>
          <div className="col-md-2">
            <ColFour />
          </div>
          <div className="col-md-2">
            <ColFive />
          </div>
        </div>
      </div>
      <div style={line1}>
        <div style={line2}></div>
      </div>
      <div className="d-flex flex-row text-center">
        <div className="text-center" style={icon}>
          <i
            class="fab fa-twitter fa-2x"
            style={{ color: "#000", marginTop: 6 }}
          ></i>
        </div>
        <div className="text-center" style={icon}>
          <i
            class="fab fa-linkedin-in fa-2x"
            style={{ color: "#000", marginTop: 6 }}
          ></i>
        </div>
        <div className="text-center" style={icon}>
          <i
            class="fas fa-play fa-2x"
            style={{ color: "#000", marginTop: 6 }}
          ></i>
        </div>
      </div>
    </div>
  );
}

export default Footer_Amnaya;
