import React from 'react'
import Sidebar from './Sidebar/Sidebar'
import { Container } from 'react-bootstrap';

function Contact() {
    return (
        <div>
            <div className="row">
                <div className="col-md-2 col-1">
                    {/* <Sidebar/> */}
                </div>
                <div className="col-md-12 col-11">
                    <div className="row m-0" style={{marginTop:50,padding:30}}>
                    <div className="col-md-6">
                        <h3 style={{fontSize:50, fontWeight:520,borderTop:"2px solid black"}}> Contact</h3>
                    </div>
                    <div className="col-md-6">
                        <h5><b>AMNAYA CORPORATION</b><br />
                        19 W STE#1018 34th Street,<br />
                        New York City, US-10001 <br />
                        T. +1 64 65 99 0344 <br />
                        business@amnayacorp.com <br />

                        US EIN# : 32-0544-720
                        </h5>
                    </div>
                    </div>
                    <div className="row" style={{marginTop:50,padding:30}}>
                    <div className="col-12">
                        <h3 style={{fontSize:50, fontWeight:520,borderTop:"2px solid black"}}>
                         Investor Relations Subscription
                        </h3>
                        <p style={{marginTop:20}}>Please register your contact details to receive our corporate news.</p>
                    </div>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
