import React from 'react'
import Image from "../../Assets/Discription.jpg"

function DiscriptionHero() {
    return (
        <div style={{marginBottom: 80}}>
        <div className="row m-0 container-fluid">
          <div
            className="col-12 d-flex text-center justify-content-center"
            style={{   marginBottom: 30 }}
          >
            <img src={Image} className="img-fluid" alt="Background_image" style={{height: 500, width:1500}} />
          </div>
        </div>
      </div>
    )
}

export default DiscriptionHero
