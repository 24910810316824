import React from 'react'

function ColThree() {
    return (
        <div>
            <h4>Careers</h4>
            <ul className="list-unstyled" style={{color: "#999999"}}>
                <li>Life At AMNAYA</li>
                <li>Network Companies</li>
                <li>Turnkey Projects</li>
            </ul>
        </div>
    )
}

export default ColThree
