import './App.css';
import {BrowserRouter as Router , Switch , Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './Components/Main/Main';
import NavBar from './Components/Navbar/NavBar';
import WeMain from './Components/Weoffer/WeMain';
import Career from './Components/Career/Main';
import Investor from './Components/Investors/Financial';
import Covertible from './Components/Investors/Covertible';
import Calerdar from './Components/Investors/Calerdar';
import Leaders from './Components/Investors/Management';
import Contact from './Components/Investors/Contact';
import Media from './Components/Media/MediaMain';
import SideNav from './Components/Investors/Sidebar/SideNav';
import JobMain from './Components/Job/JobMain';
import InvestmentMain from "./Components/Investment/InvestmentMain";
import BasicTabs from './Components/Investors/InvestorTab/InvestorTab';
import DiscriptionMain from './Components/Discription/DiscriptionMain';
import Coto from "./Components/WeOfferClick/Coto";
import Coffee from './Components/WeOfferClick/Coffee';
import AmVegan from './Components/WeOfferClick/AmVegan';
import Dairy from './Components/WeOfferClick/Dairy';
import Energy from './Components/WeOfferClick/Energy';
import Saffron from './Components/WeOfferClick/Saffron';
import Tea from './Components/WeOfferClick/Tea';
import Vigil from './Components/WeOfferClick/Vigil';

function App() {
  return (
    <>
      <div className="App">
        <Router>
          <NavBar />
          <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/offer" component={WeMain} />
            <Route exact path="/career" component={Career} />
            <Route exact path="/investor" component={BasicTabs} />
            <Route exact path="/leadership-governance" component={Leaders} />
            <Route excat path="/contact" component={Contact} />
            <Route exact path="/media" component={Media} />
            <Route exact path="/job" component={JobMain} />
            <Route exact path="/job_discription" component={InvestmentMain} />
            <Route exact path="/discription" component={DiscriptionMain} />

{/* Discription Routing Start */}
            <Route exact path="/coto" component={Coto} />
            <Route exact path="/coffee" component={Coffee} />
            <Route exact path="/amvegan" component={AmVegan} />
            <Route exact path="/dairy" component={Dairy} />
            <Route exact path="/energy" component={Energy} />
            <Route exact path="/saffron" component={Saffron} />
            <Route exact path="/tea" component={Tea} />
            <Route exact path="/vigil" component={Vigil} />
{/* Discription Routing Ending */}

          </Switch>
        </Router>
        <Router>
          <Switch>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <Route exact path="/financial" component={Investor} />
                <Route excat path="/convertible-bond" component={Covertible} />
                <Route exact path="/financial-calender" component={Calerdar} />
              </div>
            </div>
          </Switch>
        </Router>
        {/* <SideNav/> */}
        {/* <JobMain /> */}
        {/* <InvestmentMain /> */}
      </div>
    </>
  );
}

export default App;
