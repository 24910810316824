import React from "react";
import nkc from "../../Assets/img/nkc.jpg";
import akc from "../../Assets/img/akd.jpg";
import arash from "../../Assets/img/arash.jpg";
import anb from "../../Assets/img/anb.jpg";
import gordan from "../../Assets/img/gordan.jpg";
import ams from "../../Assets/img/ams.jpg";
import pra from "../../Assets/img/ParthaSarathi Mishra.jpg";


export const Advisor = [{
        Img: akc,
        title: "Anil Kumar Dubey",
        sub: "Lead Banker/ Advisor/Mentor",
        info: "International Banker and Expert on M&A.",
    },
    {
        Img: arash,
        title: "Arash Ghalehgolabbehbahani",
        sub: "Postdoctoral Research Fellow, University of Vermont",
        info: "Plant Agroecologist and Researcher.",
    },
    {
        Img: anb,
        title: "Anurag Batra",
        sub: "Chairman and Editor in Chief, Business World-INDIA",
        info: "Media Moghul, Investor and Mentor to New Businesses.",
    },
    {
        Img: ams,
        title: "Amit Shukla",
        sub: "Engineer and Indian Revenue Services Officer",
        info: "GoI Administrator, Taxation and Compliances Expert",
    },
    {
        Img: nkc,
        title: "Nand Kishore Chaudhary",
        sub: "Founder and President, Jaipur Rugs Co. Ltd",
        info: "Visionary leader and Philanthrophist. Jaipur Rugs is largest exporter of Rugs and Carpets from India."
    },
    {
        Img: pra,
        title: "ParthaSarathi Mishra",
        sub: "ParthaSarathi Mishra is Ex CHRM of Tata Steel",
        info: "",
    },
    {
        Img: gordan,
        title: "Gordon Nardell",
        sub: "QC, Barrister at Twenty Essex, London",
        info: "Specialising in Energy, Infrastructure and International Arbitration",
    },




];