import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CardData } from './CardData';
import WeCard from './WeCard';
import { TrunkeyData } from './TurnkeyData';
import Turnkey from './Turnkey';
import { Card, Button } from 'react-bootstrap';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const contain = {
    width: "18rem",
    padding: 20,
    margin: 20,
    height: 450,
  };
  const logo = {
    width: 120,
    height: 140,
  };
  const line = {
    borderWidth: 3,
    color: "#606060",
  };
  const card_body = {
    padding: 0,
  };
  const card_heading = {
    marginTop: -16,
    color: "#414040",
    //  fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 800,
  };
  const btn = {
    width: "100%",
    borderRadius: 0,
    //  fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
  };
  const info = {
    color: "#606060",
    //  fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 40,
    height: 160,
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Core Projects" {...a11yProps(0)} />
          <Tab label="Turnkey Project" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
      <div className="row m-0">
        {
            CardData.map((val, key) =>
            <div className="col-md-3">
            {/* <WeCard
            Img={val.Img} 
            title={val.title}
            info={val.info} 
            // btn={val.btn}

            /> */}
            <Card style={contain}>
      <Card.Img variant="top" src={val.Img} style={logo} />
      <hr style={line} />

      <Card.Body style={card_body}>
        <Card.Title style={card_heading}>{val.title}</Card.Title>
        <Card.Text style={info}>{val.info}</Card.Text>
        {/* <Button
          variant="outline-secondary"
          style={btn}
          // onClick={() => history.push('/discription')}
        >
          {props.btn}
        </Button> */}
        <Button  variant="outline-secondary" BaseClasses style={btn}  id={window.location.pathname == val.link ? "active" : ""} key={key} onClick={()=>{window.location.pathname = val.link}}  >Read More</Button>

      </Card.Body>
    </Card>


            
            </div>
            )
        }
    </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div className="row m-0">
        {
            TrunkeyData.map((val) =>
            <div className="col-md-3">
            <Turnkey
            title={val.title}
            country={val.country} 
            btn={val.btn}
            />
            </div>
            )
        }
    </div>
      </TabPanel>
      
    </Box>
  );
}