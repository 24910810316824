import React from "react";
import { Container } from "react-bootstrap";
import Image from "../../Assets/social_media.jpg";

function MediaCard(props) {
  const writer_name = {
    textDecoration: "none",
  };
  const text_name = {
    fontWeight: "bold",
    color: "black",
  };
  const discription={
    color: "#666"
  }
  return (
    <div style={{ marginBottom: 40 }}>
      <Container>
        <hr />
        <div className="row m-0">
          <div className="col-md-3">
            <div>
              <img
                src={Image}
                alt="social_media"
                style={{ height: 200, width: 300 }}
              />
            </div>
          </div>
          <div className="col-md-9">
            <h6 style={{ color: "#706666" }}>{props.date}</h6>
            <a href="" style={writer_name}>
              <h5 style={text_name}>{props.tag}</h5>
            </a>
            <a href="" style={writer_name}>
              <p style={discription}>{props.discription}</p>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MediaCard;
