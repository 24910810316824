import React from "react";
import { Table } from "react-bootstrap";

function InvestmentPosition() {
  const contain = {
    backgroundColor: "#ddd",
    color: "#000",
    padding: 20,
    // marginBottom: 40
  };
  return (
    <div style={contain}>
      <div className="container">
        <h1>Open Positions</h1>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>POSITION</th>
              <th>JOB TYPE</th>
              <th>LOCATION</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Growth & Private Equity Intern (m/f/d) @ Global Founders Capital
              </td>
              <td>Intern</td>
              <td>Berlin, Germany</td>
            </tr>
            <tr>
              <td>
                Growth & Private Equity Intern (m/f/d) @ Global Founders Capital
              </td>
              <td>Intern</td>
              <td>Berlin, Germany</td>
            </tr>
            <tr>
              <td>
                Growth & Private Equity Intern (m/f/d) @ Global Founders Capital
              </td>
              <td>Intern</td>
              <td>Berlin, Germany</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default InvestmentPosition;
