import { Tabs, Tab, Accordion, Table } from 'react-bootstrap';
import React from 'react'
import WeCard from './WeCard';
import { CardData } from './CardData';

import BasicTabs from './InnerTab';
// import { GlobalData } from './GlobalData';
// import { TrunkeyData } from './TurnkeyData';
// import { DataCenterData } from './DataCenterData';
// import { CenterOfExcellence } from './CenterOfExcellence';
// import Turnkey from './Turnkey';



function WeTab (){
    const cont={
        backgroundColor: "#fff",
    margin: 10,
    borderRadius: 10,
    padding: 10,
    height: "90%",
    }
    return (
        <div style={cont}>
            <Tabs defaultActiveKey="Core Projects" id="uncontrolled-tab-example" className="mb-3">
  <Tab eventKey="Core Projects" title="PROJECTS">
  <BasicTabs />
  {/* <Accordion defaultActiveKey="1">
  <Accordion.Item eventKey="1">
    <Accordion.Header>Core Projects</Accordion.Header>
    <Accordion.Body>
    <div className="row m-0">
        {
            CardData.map((val) =>
            <div className="col-md-3">
            <WeCard
            Img={val.Img} 
            title={val.title}
            info={val.info} 
            btn={val.btn}
            />
            </div>
            )
        }
    </div>
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2">
    <Accordion.Header>Turnkey Project</Accordion.Header>
    <Accordion.Body>
    <Table striped bordered hover striped>
            <thead>
                <tr>
                    <td>Project</td>
                    <td>Location</td>
                    <td>Action</td>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td>Patent Research Outsourcing for a US based Biopharmaceuticals Giant</td>
                <td>India</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Olive Farms and Related Technology</td>
                <td>India and Adjacent Territories</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Goat Cheese Manufacturing Plants	</td>
                <td>India</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Gecko Research Laboratory for Cancer Treatment	</td>
                <td>India/Bangladesh</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Medical Insurance Process based BPO – Voice Calling</td>
                <td>India/Phliippines</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Blockchain and AI enabled Platform for Multi Family Properties	</td>
                <td>India</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Setting up Schools based on US Schooling Systems, known as American Schools</td>
                <td>India</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Robotics Process Automation based Service Platforms for Pharmaceutical/Manufacturing.	</td>
                <td>India</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Medical Tourism(Uberized Form)</td>
                <td>India</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Data Vault for Vigilance and Security Systems</td>
                <td>India/Indonesia	</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Olive based Wine Manufacturing</td>
                <td>India</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Carpets Cluster enabled by Blockchain and Artificial Intelligence	</td>
                <td>India/Poland/Romania</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Knowledge Process Outsourcing using AI</td>
                <td>India</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            <tr>
                <td>Solar Power Networking using Blockchain for Effective Distribution</td>
                <td>GLOBAL</td>
                <td><a href="#" style={{textDecoration:"none"}}>Downloads</a></td>
            </tr>
            </tbody>
            </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>     */}
            </Tab>
  {/* <Tab eventKey="Global Center" title="GLOBAL CENTERS">
  <div className="row m-0">
  {
      GlobalData.map((val) =>
      <div className="col-md-3">
      <WeCard 
      
      title={val.title}
      info={val.info} 
      btn={val.btn}
       />
       </div>
      )
  }
  </div>
  </Tab>
  <Tab eventKey="Turnkey Projects" title="TURNKEY PROJECTS">
  <div className="row m-0">
  {
      TrunkeyData.map((val) =>
      <div className="col-md-3">
      <WeCard 
      title={val.title}
      info={val.info} 
      btn={val.btn}
       />
       </div>
      )
  }
  </div>
  </Tab>
  <Tab eventKey="Data Center" title="DATA CENTER">
    <div className="row m-0">
        {
            DataCenterData.map((val) =>
            <div className="col-md-3">
                <WeCard
                Img={val.Img} 
                title={val.title}
                info={val.info} 
                btn={val.btn}
                />
            </div>
            )
        }
    </div>
  </Tab> */}
  <Tab eventKey="Center of Excellence" title="CENTER OF EXCELLENCE">
    {/* <div className="row m-0">
        {
            CenterOfExcellence.map((val) =>
            <div className="col-md-3">
                <WeCard
                Img={val.Img} 
                title={val.title}
                info={val.info} 
                btn={val.btn}
                />
            </div>
            )
        }
    </div> */}
    <Accordion defaultActiveKey="0">
  <Accordion.Item eventKey="0">
    <Accordion.Header>Global Centre</Accordion.Header>
    <Accordion.Body>
    A Global Centre proposes upon a business idea. A circulation is made to all other Global Centre to have their interest. Those that are interested form to build a Sourcing Platform to support the project.
    <ul>
      <li>Global Centre for Blockchain & AI</li>
      <li>Global Centre for Corporate Diplomacy</li>
      <li>Global Centre for Corporate Social Responsibility</li>
      <li>Global Centre for Entrepreneurship & Investment</li>
      <li>Global Centre for Trade & Investments in Energy Sources</li>
      <li>Global Centre for Investment & Intellectual Property Rights</li>
    </ul>
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Data Centre</Accordion.Header>
    <Accordion.Body>
    AMNAYA Data Centre is responsible for all background related research, data aggregation and analytics. The Data Centre is also a hub for follow up and vigilance for execution and deployment of resources for all projects based on cost and benefits analysis. 
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2">
    <Accordion.Header>Venture Lab</Accordion.Header>
    <Accordion.Body>
    AMNAYA Venture Lab has twin teams of Technology Innovation and Execution Leaderships. Both the team join in with other Centre to build the business and the product.
    </Accordion.Body>
  </Accordion.Item>
</Accordion>
  </Tab>
</Tabs>
        </div>
    )
}
    
export default WeTab
