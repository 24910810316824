import React from "react";
import NrmlCard from "../Extra/NrmlCard";

function MidMain() {
  const contain = {
    padding: 20,
  };
  const cent={
    display: "flex" ,
    alignItems: "center", 
    justifyContent: "center"
  };
  const line1={
    backgroundColor: "#212428",
    height: 1,
    marginTop: 20,
    marginBottom: 40

  };
  const line2={
    backgroundColor: "#212428",
    height: 3,
    width: "15%",
    borderRadius: 10,
  };
  return (
    <div style={contain}>
    <div style={line1}><div style={line2}></div></div>
<h1 style={{marginBottom: 20}}>AMNAYA</h1>
      <div className="row m-0">
        <div className="col-md-4 " style={cent}>
          <NrmlCard
            head="Projects"
            info="We have a set of already developed Proof of Concepts along with
            dynamic financial models that can be customised to suit timeframes
            and geographies."
          />
        </div>
        <div className="col-md-4" style={cent}>
          <NrmlCard
            head="Holacracy"
            info="AMNAYA ensures that 'evolution' does not imply educational complexity,instead it implies,creative means that generate desired values for humankind."
          />
        </div>
        <div className="col-md-4" style={cent}>
          <NrmlCard head="Valuation" 
              info="AMNAYA values all its Ventures based on IFRS/Mark to Market
"
          />
        </div>
      </div>
    </div>
  );
}

export default MidMain;
