import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Advisor } from "./../Advisor";
import InCard from "./../InCard";
import { Leaders } from "./../LeadersData";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InnerTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Management Board" {...a11yProps(0)} />
          <Tab label="Board of Advisors" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="row">
          {Leaders.map((val) => (
            <div className="col-md-3" style={{ marginBottom: 10 }}>
              <InCard
                Img={val.Img}
                title={val.title}
                sub={val.sub}
                info={val.info}
              />
            </div>
          ))}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="row">
          {Advisor.map((val) => (
            <div className="col-md-3 col-12" style={{ marginBottom: 10 }}>
              <InCard
                Img={val.Img}
                title={val.title}
                sub={val.sub}
                info={val.info}
              />
            </div>
          ))}
        </div>
      </TabPanel>
    </Box>
  );
}
