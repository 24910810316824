import React from 'react'

function ColFour() {
    return (
        <div>
            <h4>Investors</h4>
            <ul class="list-unstyled" style={{color: "#999999"}}>
                <li>Financial Information</li>
                <li>Convertible Bond</li>
                <li>Financial Calendar</li>
                <li> Leadership & Governance</li>
                <li> Contact</li>
            </ul>
        </div>
    )
}

export default ColFour
