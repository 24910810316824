import React from 'react'
import { Card, Button } from 'react-bootstrap';

function Turnkey(props) {
    const contain={
        width: "18rem",
        padding: 10 ,
        margin: 20,
        height: 250
    
    };
    const info={
        color: "#606060",
       //  fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        marginTop:70

     };
     const btn={
        width: "100%",
        borderRadius: 0,
       //  fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 500,

     };
    return (
        <div>
            <Card  style={contain}>
            <Card.Body>
                <Card.Title style={{height: 60}}>{props.title}</Card.Title>
                <Card.Text style={info}>
                {props.country}
                </Card.Text>
                <Button variant="outline-primary" style={btn}>{props.btn}</Button>
            </Card.Body>
            </Card>
        </div>
            )
        }

export default Turnkey
