import React from 'react'
import { Container, Button } from "react-bootstrap";

function CTA() {
  return (
    <div>
      {/* <div className="main-content">

            <div className="container-fluid container-fluid--small">

                <div className="block-region-hero"><div className="block-block-content">


            
                    <div><div className="hero-nav-pulled flex-md flex-center-y">
            <div className="hidden-xs hidden-sm">Feel free to contact us at anytime.<br/><a href="#"><b><span>business@amnayacorp.com</span></b></a></div>

            <div className="text-center"><a className="hidden-md hidden-lg btn btn-default btn-lg btn-block js-scroll" href="#">contact us</a> <a className="hidden-xs hidden-sm btn btn-default btn-lg btn-xs-block js-scroll" href="media.html#AMNAYA-news">AMNAYA news</a> <a className="hidden-xs hidden-sm btn btn-default btn-lg btn-xs-block js-scroll" href="media.php#portfolio-news">portfolio news</a></div>
            </div>
            </div>
            
            </div>
            </div>
            </div>
            </div> */}
      <Container>
        <div className="row">
          <div className="col-md-6" style={{ marginBottom: 20 }}>
            <div className="d-flex flex-column text-center">
              Feel free to contact us at anytime.
              <br />
              <a href="#">
                <b>
                  <span>business@amnayacorp.com</span>
                </b>
              </a>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column text-center">
            {/* <div className="text-center"><a className="btn btn-default btn-lg btn-block js-scroll" href="#">contact us</a> <a className="hidden-xs hidden-sm btn btn-default btn-lg btn-xs-block js-scroll" href="media.html#AMNAYA-news">AMNAYA news</a> <a className="hidden-xs hidden-sm btn btn-default btn-lg btn-xs-block js-scroll" href="media.php#portfolio-news">portfolio news</a></div> */}
            <Button variant="outline-secondary" style={{ marginBottom: 20 }}>
              Contact Us
            </Button>
            <div className="row d-flex text-center">
              <div className="col-md-6 d-flex flex-column text-center">
                <Button
                  variant="outline-secondary"
                  style={{ marginBottom: 20 }}
                >
                  AMNAYA News
                </Button>
              </div>
              <div className="col-md-6 d-flex flex-column text-center">
                <Button
                  variant="outline-secondary"
                  style={{ marginBottom: 20 }}
                >
                  Portfolio News
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CTA
