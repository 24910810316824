import React from 'react'
import CTA from './CTA'
import Hero from './Hero'
import MediaCard from './MediaCard';
import { MediaData } from './MediaData';
import Footer_Amnaya from './../Footer/Footer';

function MediaMain() {
    return (
        <div>
            <Hero/>
            <CTA/>
            {
                MediaData.map((val) =>
                <MediaCard 
                    date={val.date}
                    tag={val.tag}
                    discription={val.discription}
                />
                )
            }
        <Footer_Amnaya />
            
        </div>
    )
}

export default MediaMain
