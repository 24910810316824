import React from 'react'
import WeCard from './WeCard';
import WeTab from './WeTab';
import Hero from '../../Assets/hero.jpg';
import Footer_Amnaya from '../Footer/Footer';

function WeMain() {
    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <img src={Hero} alt="" srcset="" className="img-fluid"/>
                </div>
            </div>
            <WeTab />
            <Footer_Amnaya/>
        </div>
    )
}

export default WeMain
