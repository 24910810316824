import React from 'react'

function ColTwo() {
    return (
        <div>
            <h4>We offer</h4>
            <ul class="list-unstyled" style={{color: "#999999"}}>
                <li>Core Projects</li>
                <li>Regional Groups</li>
                <li>Trunkey Bussiness</li>
            </ul>
        </div>
    )
}

export default ColTwo
