import React from 'react'
import TypeWriterEffect from 'react-typewriter-effect';

function Type() {
    return (
        <>
        <div className= "row">
        <div className=" col-12 d-flex text-center justify-content-center" style={{flexDirection: "column", marginBottom:80}}>
            <h3>Welcome to AMNAYA’s New York HQ.</h3>
            <TypeWriterEffect
            
            textStyle={{
                fontFamily: 'ZenOldMincho',
                color: '#000',
                fontWeight: 500,
                fontSize: '2em',
            }}
            startDelay={2000}
            cursorColor="#000"
            multiText={[
                'We enable your entrepreneurship.',
                'We further your career opportunities.',
                'We help you forge your own success.',
                'We entrust you with great responsibility.',
                'We encourage you to take the initiative.',
            ]}
            multiTextDelay={1000}
            typeSpeed={30}
            />
        </div>
        </div>
        </>
    )
}

export default Type
