import React from 'react'
import Sidebar from './Sidebar/Sidebar'
import { Container, Table } from 'react-bootstrap';
import Footer_Amnaya from '../Footer/Footer';


function Calerdar() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-12">
            <h3 style={{ fontSize: 50, fontWeight: 520 }}>
              {" "}
              Financial Calendar
            </h3>

            <Table striped bordered hover style={{ marginTop: 50 }}>
              <tr>
                <th>Jan 1st 2019</th>
                <td>Financial Calendar begins</td>
              </tr>
              <tr>
                <th>Mar 15th 2019</th>
                <td>Tax Filling</td>
              </tr>
              <tr>
                <th>June 20th 2019</th>
                <td>Annual Meetings</td>
              </tr>
              <tr>
                <th>Aug 15th 2019</th>
                <td>Mid Year Financial Performance</td>
              </tr>
              <tr>
                <th>Dec. 31st 2019</th>
                <td>Financial Calendar closes</td>
              </tr>
            </Table>
          </div>
        </div>
      </div>
    );
}

export default Calerdar
