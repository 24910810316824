import React from 'react'
import InvestmentAbout from './InvestmentAbout'
import InvestmentPosition from './InvestmentPosition'
import Footer_Amnaya from './../Footer/Footer';

function InvestmentMain() {
    return (
        <div>
            <InvestmentAbout />
            <InvestmentPosition />
            <Footer_Amnaya />
        </div>
    )
}

export default InvestmentMain
