import React from 'react'
import Sidebar from './Sidebar/Sidebar';
import { Container } from 'react-bootstrap';
import { Leaders } from './LeadersData';
import InCard from './InCard';
import { Advisor } from './Advisor';

function Management() {
    return (
        <div>
            <div className="row m-0">
                <div className="col-md-12 col-12">
                  
                    <h3 style={{fontSize:50, fontWeight:520,borderBottom:"2px solid black"}}> Leadership & Governace</h3>
                    <h5 style={{borderBottom:"2px solid black"}}>Management Board</h5><br />
                    <div className="row">
                    {
                        Leaders.map((val) =>
                        <div className="col-md-3" style={{marginBottom:10}}>
                            <InCard
                            Img={val.Img} 
                            title={val.title}
                            sub={val.sub}
                            info={val.info} 
                            />
                        </div>
                        )
                    }
                    </div><br />
                    <h5 style={{borderBottom:"2px solid black"}}>Board of Advisors</h5><br />
                    <div className="row">
                    {
                        Advisor.map((val) =>
                        <div className="col-md-3 col-12" style={{marginBottom:10}}>
                            <InCard
                            Img={val.Img} 
                            title={val.title}
                            sub={val.sub}
                            info={val.info} 
                            />
                        </div>
                        )
                    }
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Management
