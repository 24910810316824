import React from 'react'

function Location() {
    const line1={
        backgroundColor: "#000",
        height: 1,
        marginTop: 20,
        marginBottom: 40
    
      };
      const line2={
        backgroundColor: "#fff",
        height: 3,
        width: "15%",
        borderRadius: 10,
      };
      const contain = {
        padding: 20,
        paddingTop:100,
        borderTop:'1px solid #fff'
      };
    return (
        <>
          <div className="row">
                <div className="col-12 d-flex text-center justify-content-center" style={{marginBottom:50}}>
                    <h5>AMNAYA's Location</h5>
                </div>
          </div>  
              <div className="row m-0 loc" style={contain}>
              <div style={{backgroundColor: "#fff", height: 1,marginTop: 20, marginBottom: 40}}><div style={{backgroundColor: "#fff",height: 3,width: "15%",borderRadius: 10,}}></div></div>
              <div className="col-md-6" >
              
                <h3  style={{color:'#fff',fontFamily:'Neuzeit, sans-serif',fontWeight:700,fontSize:40}}><amp>AMNAYA HQ</amp><div>Culture in New York City </div></h3>
                </div>
                <div className="col-md-6" >
                <p  style={{color:'#fff'}}>New York City occupies a special place in the American consciousness as the tumultuous seat of our financial markets and the buzzing capital of our culture. New York is celebrated for its wealth of nationalities, ethnicities and languages.</p>
                <div class="text-right"><a class="margin-top-xl btn btn-ghost btn-lg btn-xs-block" href="#">New York City &amp; CULTURE</a></div>
                </div>
                <p class="margin-top-xxl text-white title-with-stripe text-center">Working at AMNAYA</p>

                </div>
        </>
    )
}

export default Location
