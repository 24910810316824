import React from 'react'
import { Container } from 'react-bootstrap'
import Footer_Amnaya from '../Footer/Footer';
import DescriptionButton from './DescriptionButton';
import DiscriptionHero from './DescriptionHero';
import DescriptionInfo from './DescriptionInfo';

function DiscriptionMain() {
    return (
        <div>
            <DiscriptionHero />
            <DescriptionInfo />
            <DescriptionButton />
            <Footer_Amnaya />
        </div>
    )
}

export default DiscriptionMain
