export const MediaData=[{
    date: "July 23, 2020",
    tag: "@RocketBerlin",
    discription: 'Got the Corona blues? Test Vaha, the interactive fitness mirror,right inside your home and get into the flow! 30 days free trialhttp://vaha.com #vaha #workout #personaltraining'
},
{
    date: "July 23, 2020",
    tag: "@RocketBerlin",
    discription: 'Got the Corona blues? Test Vaha, the interactive fitness mirror,right inside your home and get into the flow! 30 days free trialhttp://vaha.com #vaha #workout #personaltraining'
},
{
    date: "July 23, 2020",
    tag: "@RocketBerlin",
    discription: 'Got the Corona blues? Test Vaha, the interactive fitness mirror,right inside your home and get into the flow! 30 days free trialhttp://vaha.com #vaha #workout #personaltraining'
},
{
    date: "July 23, 2020",
    tag: "@RocketBerlin",
    discription: 'Got the Corona blues? Test Vaha, the interactive fitness mirror,right inside your home and get into the flow! 30 days free trialhttp://vaha.com #vaha #workout #personaltraining'
},

]